$custom-file-text: (
  en: "Browse",
  ja: "参照"
);

@import '~bootstrap/scss/bootstrap';
@import '~flatpickr/dist/flatpickr';

// Select2
@import '~select2';
@import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4';
@import 'bootstrap-extend';


body {
  font-size: 0.875rem;
  background-color: #dfdfdf;
  // background: url("../images/magickiri_background_screenshot.png");
}

.main-header {
  // background-image: url(/packs/media/images/magickiri_background_screenshot-d5134e1….png);
  background-color: rgba(255, 255, 255, 0.2);
  background-blend-mode: lighten;
}

// https://github.com/bootstrap-ruby/bootstrap_form/blob/master/app/assets/stylesheets/rails_bootstrap_forms.css
.rails-bootstrap-forms-date-select select,
.rails-bootstrap-forms-time-select select,
.rails-bootstrap-forms-datetime-select select {
  display: inline-block;
  width: auto;
}

.rails-bootstrap-forms-error-summary {
  margin-top: 10px;
}

label.required:after {
  content:" *";
  color: red;
}

.login-page {
  text-align: center;
  padding-top: 60px;
  height: 100vh;
  position: relative;

 .notice {
    color: #ffffff;
  }
  .form-wrap {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -10%;
  }

  .form-signin {
    display: block;
    max-width: 50%;
    min-width: 300px;
    text-align: left;
    padding: 15px;
    background-color: #ffffff;
    margin: 0 auto;
    &.password {
      max-width: 60%;
    }
  }
}

.svg-wrap {
  position: relative;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 37%);
  padding:0 0.2rem 0.2rem;
  width: 100%;
  .svg-map {
    overflow: hidden;
    max-height: 1024px;
    min-height: 200px;
    height: 100%;
    background-color: #ffffff;
    svg {
      max-height: 1024px;
      width: 100%;
      height: 100%;
    }
  }

  .map-menu-wrap {
    position: sticky;
    top: 5%;
    padding:0.5rem 1rem;
    border-bottom: 1px solid #efefef;
    background: #ffffff;
    z-index: 2;
    #position-text {
      box-sizing: content-box;
    }
  }
}


// Turbolinks
.turbo-progress-bar {
  height: 5px;
  background-color: green;
}

.clipboard-copy {
  cursor: pointer;
}

// 要素を角ばらせる
.card,
.btn,
.form-control,
.custom-select,
.modal-content,
.dropdown-menu,
.breadcrumb,
.alert,
.select2-container--bootstrap4 .select2-selection {
  border-radius: 0;
}
.card-header{
  background-color: transparent;
}

.main-header {
  // background-image: url("../images/magickiri_background_screenshot.png");
  // background-color: rgba(255, 255, 255, 0.2);
  // background-blend-mode: lighten;

  .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .navbar-brand {
    color: #fff;
  }
}

/*
 * Sidebar
 */
 .container-with-sidebar {
  padding-left: 65px;
  .sidebar {
    overflow:hidden;
    transition: width 0.2s , left 0.2s;
    width: 50px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 56px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #484848;

    .button-sidebar-toggle {
      width: 50px;
      position: fixed;
      bottom: 0;
      overflow: hidden;
    }
    .icon {
      width: 35px;
    }
  }

  //最初は出さない
  .btn-collapse {
    display: none;
  }
  .sidebar-tooltip-container {
    display: block;
    width: 55px;
    height: 42px;
    position: absolute;
    left: 0;
  }

  &.sidebar-manual-expand {
    padding-left: 215px;
    .sidebar {
      left: 0;
      width: 200px;
    }
    .btn-expand {
      display: none;
    }
    .btn-collapse {
      display: block;
    }
    .button-sidebar-toggle {
      width: 200px;
    }
    .sidebar-tooltip-container {
      display: none;
    }
  }

  //画面サイズ小さい
  @media (max-width: 768px) {
    padding-left: 15px;
    .drawer-btn-wrap .navbar-dark {
      margin-right: 0.5rem;
    }
    .sidebar {
      left: -220px;
    }
    //expandしたまま小さくした場合の制御
    &.sidebar-manual-expand {
      padding-left: 15px;
      .sidebar {
        left: -220px;
      }
    }
  }
  //横幅が狭くなったときにハンバーガーでdrawer表示のクラス
  .sidebar-drawer {
    left: 0 !important;
    width: 200px;
  }
  //画面サイズ中
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 65px !important;
    .sidebar:not(.sidebar-drawer) {
      width: 50px;
      .nav-item {
        overflow: hidden;
      }
      .sidebar-tooltip-container {
        display: block;
      }
    }
  }
  //画面幅がものすごく小さい時
  @media (max-width: 300px) {
    .drawer-btn-wrap{
      margin-top : 50px;
    }
  }
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);;
  right: 0;
  z-index: 10;
  display: none;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 56px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #ffffff;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  background-color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}



/*
 * Content
 */

[role="main"] {
  padding-top: 60px; /* Space for fixed navbar */
}

.table {
  td {
    vertical-align: middle;
  }
}
.pagination {
  margin-bottom: 0;
}
.card-header.sticky-top {
  top: 56px;
  background-color: #efefef;
}
@include media-breakpoint-down(xs) {
  .card-header.sticky-top {
    top: 96px;
  }
}

.page-title {
  border-bottom: 1px solid #eee;
}

.breadcrumbs-container {
  width: 100%;
}
.breadcrumb {
  margin-bottom: 0rem;
  background-color: transparent;
}
.overflow-wrap {
  overflow-x: auto;
  table {
    .operation {
      width: 10%;
    }
    th {
      white-space: nowrap;
      max-width: 30%;
    }
  }
}

#__area_region__ {
  polygon {
    stroke-width: 0.1;
  }

  text {
    font-size: 1px;
    font-weight: bold;
  }
}

.area-region.tooltip .arrow {
  display: none;
}
.area-region.tooltip .tooltip-inner {
  max-width: 100%;
  text-align: left;
  background-color: #FFFFFF;
  border: 1px solid #333;
  color: black;
}

.asset-position.tooltip {
  .tooltip-inner {
    max-width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #333;
    color: black;
  }

  .arrow {
    display: none;
  }
}

.locator-position.tooltip {
  .tooltip-inner {
    max-width: 100%;
    text-align: left;
    background-color: #FFFFFF;
    border: 1px solid #333;
    color: black;
  }

  .arrow {
    display: none;
  }
}



.badge-status {
  font-size: 1em;
}

.nav-tabs {
  border-bottom: 1px solid #484848;
  .nav-link.active{
    color: #495057;
    background-color: #fff;
    border-color: #484848 #484848 #fff;
  }

  .nav-link{
    @include hover-focus() {
      border-color: #484848 #484848 #dfdfdf;
    }
  }
}


.status.false {
  color: $text-muted !important;
  a,label,span,button,input{
    color: $text-muted !important;
  }
}

.text-line-right{
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  &:first-child {
    margin-left: 0rem;
  }
  margin-left: 0.5rem;
  &::after {
    content: "";
    height: 2px;
    display: block;
    width: 1.5rem;
    margin-left: .4em;
  }
  &.red {
    &::after {
      background: #ff0000;
    }
  }
  &.green {
    &::after {
      background: #00ff00 ;
    }
  }
  &.blue {
    &::after {
      background: #0000ff;
    }
  }
}

.g-header {
  @extend .navbar-dark;
  @extend .bg-dark;

  &.development {
    background-color: #058D6A !important;
  }
  &.dev {
    background-color: #DE6813 !important;
  }
  &.staging {
    @extend .bg-info;
  }
  &.production {
    @extend .bg-dark;
  }
}

svg {
  .peer {
    stroke: #00995c !important;
    stroke-width: 0.05px;
  }
  .nearest-peer {
    stroke: #00995c !important;
    stroke-width: 0.05px;
    fill: #00cc7a !important;
  }
  .locator-disabled {
    opacity: 0.3;
  }
}

.playcontrol-wrap {
  strong {
    width: 30%
  }
  .playcontrol {
    @extend .d-flex;
    @extend .flex-wrap;
    align-items: center;
    .inputcontrol {
      position:relative;
      .rg-label {
        font-size: 10px;
      }
      input {
      width: 400px;
      cursor: pointer;
      margin: 5px 35px 0 0;
      }
      .min {
        position: absolute;
        bottom: -15px;
        left: 0;
        font-size: 10px;
      }
      .max{
        position: absolute;
        bottom: -15px;
        left: 390px;
        font-size: 10px;
      }
    }
    .playcontrol-btns {

      //ボタンの配置を調整
      @media (min-width: 630px) and (max-width: 991px) {
        margin-top: 0;
      }
      @media (max-width: 629px) {
        margin-top: 2rem;
      }

      .btn:not(:last-child):not(.dropdown-toggle),
      .btn-group:not(:last-child) > .btn {
        @include border-right-radius(0);
        @include border-left-radius(0);
      }
      .btn:not(:first-child),
      .btn-group:not(:first-child) > .btn {
        @include border-right-radius(0);
        @include border-left-radius(0);
      }
    }
    .setting-btn {
      height: 32px;
      box-shadow: inset 0 0 0 1px #bfbfbf;
      button {
        box-shadow: inset 0 0 0 #ddd;
      }
      .dropdown-menu {
        box-shadow: inset 0 0 0 1px #bfbfbf;
      }
      &:hover {
        background-color: #f2f2f2;
        box-shadow: inset 0 0 0 2px #bdbdbd, 0 2px 2px 0 rgb(0 0 0 / 8%);
      }
    }
  }
}

.tip {
  position: absolute;
  z-index: 100;
}

.select2-border-0 + .select2, .select2-selection__rendered {
  width: 100% !important;
  line-height: calc(1.5em + .5rem) !important;
  height: calc(1.5em + .5rem + 2px) !important;
  .select2-selection {
    border-radius: 0;
    height: calc(1.5em + .5rem + 2px) !important;
  }
}

svg {
  #__duplicate_polygon__ {
    isolation: isolate;

    .duplicate-polygon {
      mix-blend-mode: screen;
      filter: invert(1);
      animation: flash 1s linear infinite;
    }
  }
}

.drag {
  cursor: move;
  position: relative;
}



@keyframes flash {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

hex-color-picker {
  width: auto;
}
