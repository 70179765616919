.card-header {
  @extend .d-flex;
  @extend .flex-wrap;
  @extend .align-items-center;
}
.card-body {
  &.intensions-list-group {
    @extend .pt-0;
    @extend .pb-0;
  }
}

.usageguide {
  @extend .d-flex;
  @extend .align-items-center;
  flex-wrap: wrap;

  .item {
    .colorBox {
      width:25px;
      height: 10px;
      margin-right:5px;
    }
    @extend .d-flex;
    @extend .align-items-center;
    margin-right: 15px;
  }
}

.list-group {
  @extend .list-group;
  @extend .list-group-flush;
}

.list-row{
  @extend .d-flex;
  @extend .flex-row;
  @extend .flex-wrap;
}

.list-item-label {
  @extend .text-muted;
}

.button-md-right-flex {
  width: 100%;
  margin: 0 0 0 auto;
  @extend .d-md-flex;
  @extend .align-items-center;
  @extend .justify-content-end;
  .btn {
    @extend .mb-1;
  }
}

.button-sm-right-flex {
  width: 100%;
  margin: 0 0 0 auto;
  @extend .d-sm-flex;
  @extend .align-items-center;
  @extend .justify-content-end;
  .btn {
    @extend .mb-1;
  }
}

.paginate-position-right-flex {
  @extend .d-flex;
  @extend .justify-content-end;

}

.card-table {
  @extend .table;
  @extend .table-sm;
  @extend .table-responsive-sm;
  @extend .table-hover;
  @extend .text-monospace;

  border-bottom: $border-width solid $border-color;
  border-top: none;

  thead tr th {
    border-top: none;
  }
  tbody {
    td {
      padding-top: 10px;
      padding-bottom: 10px;
      //3点リーダーのwidthを決める
      &.menu-wrap {
        width: 30px;
      }
    }
    tr {
      &.highlight {
        background-color: #F2FAFE;
      }
      :first-child {
        td {
          border-top: none;
        }
      }
    }
  }
}

.menu-wrap {
  .dropdown{
    @extend .dropdown;
    text-align: right;
    margin: 0 0 0 auto;

    .dropdown-item {
      @extend .dropdown-item;
      font-size: 0.8rem;
      padding: 0.25rem 1rem;
    }
    .ellipsis-menu {
      @extend .dropdown-toggle;
      padding: 0 10px;
      display:block;
      &::after {
        display: none;
      }
    }
  }
}

.card {
  .card-header {
    .dropdown-item {
      font-size: 0.8rem;
    }
  }
}

.dropdown-menu {
  li {
    position: relative;
  }
  li:hover {
    .dropdown-submenu {
      display: block;
    }
  }
  .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -9px;
  }
  .dropdown-submenu-left {
    right: 99%;
    left: auto;
  }
  .dropdown-item-checked::before {
    content: "✓";
    font-size: 0.8rem;
    margin-right: 0.3rem;
  }
}

.g-header {
  @extend .navbar-dark;
  @extend .bg-dark;

  &.development {
    background-color: #058D6A !important;
  }
  &.dev {
    background-color: #DE6813 !important;
  }
  &.staging {
    @extend .bg-info;
  }
  &.production {
    @extend .bg-dark;
  }
}

.column-divide {
  .col-sub {
    @extend .mt-3;
  }
}
.main-two-column {
  .column-divide {
    position: relative;
    @extend .row;
    .column-sticky {
      position: sticky;
      top: 0;
    }
    .col-main {
      @extend .col-lg-9;
      @extend .order-lg-2;

      .card-header.hidable {
        @extend .d-lg-none;
      }
    }
    .col-sub {
      @extend .col-lg-3;
      @extend .pr-lg-0;
      @extend .order-lg-1;
      @extend .mt-lg-0;
      @extend .mt-3;
    }
    .visible-btn {
      @extend .d-lg-block;
    }
    .hidable-btn {
      @extend .d-lg-none;
    }

  }
  .card-table {
    td,th {
      @extend .text-nowrap;
    }
  }
}

.toolbar-btns {
  @extend .text-nowrap;
  @extend .ml-auto;
  @extend .mr-0;
  @extend .text-nowrap;
  @extend .d-flex;
  @extend .flex-wrap;
}
